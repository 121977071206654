export const SHOP_SOURCE = {
  YESCLOUD: 'yescloud',
  API: 'API',
};

export const STORAGE_KEY = {
  CART: 'cart',
  CUSTOMER_ID: 'customer-id',
  ORDERS: 'orders',
  CUSTOMER_DETAILS: 'customer-details',
};

export const SERVER = {
  URL:
    process.env.REACT_APP_BASE_API_URL ||
    // 'https://sanoft-mini-erp.uc.r.appspot.com/api',
    // 'http://localhost:3000/api',
    'https://api-pos-legacy.sanoft.com',
};

export const DEFAULT_CURRENCY = 'Rs';

export const ROUTES = {
  SHOP_HOME: '/:shopId',
  ORDERS: '/:shopId/orders',
  ORDER_DETAILS: '/:shopId/orders/:orderId',
  PAYMENT_STATUS: '/:shopId/payment-status',
  PRODUCT_DETAILS: '/:shopId/:categoryId/:productId',
  CONTACT_US: '/:shopId/contact-us',
};

export const DEFAULT_HOST = 'cart.sanoft.com';

export const THEME_CONFIG = {
  'ulcc.cart.sanoft.com': {
    name: 'ULCP',
    shop_id: 1,
    theme: {
      primary: '#00923e',
      secondary: '#236632',
      tertiary: '#3c2a21',
    },
  },
  'www.avocart.in': {
    name: 'AVOCART',
    shop_id: 438,
    theme: {
      primary: '#88113F',
      secondary: '#88143F',
      tertiary: '#89113F',
    },
  },
};

export const DEFAULT_CONFIG = {
  name: 'SANOFT',
  shop_id: null,
  theme: {
    primary: '#272C86',
    secondary: '#1F236B',
    tertiary: '#9DA2FF',
  },
};

export const MAX_API_CALLS_FOR_POLLING = 75;

export const TIME_OUT_MESSAGE = 'Payment failed. If payment is deducted, please contact the counter.';

export const QR_URL = 'https://qr.sanoft.com/oo?d=';

export const QR_PREFIX = 'oo:';

export const SANOFT_WEB_URLS = {
  PRIVECY_POLICY: 'https://www.sanoft.com/privacy-policy',
  TERMS: 'https://www.sanoft.com/terms-and-conditions',
  REFUND_POLICY: 'https://www.sanoft.com/refund-policy',
  CONTACT_US: 'https://www.sanoft.com/#contact-us',
};

export const ORDER_STATUS = {
  PENDING: 'PENDING',
  DELIVERED: 'DELIVERED',
};
