import React, { useContext } from 'react';
import styles from './styles.module.css';
import { ShopContext } from '../../../contexts/ShopContext';
import { SANOFT_WEB_URLS } from '../../../const';

function Contactus() {
  const { shop } = useContext(ShopContext);

  const redireatWeb = (url) => {
    window.location.href = url;
  };

  return (
    <div className={styles.container}>
      <h1 calssName={styles.head}>{shop.name}</h1>
      <button onClick={() => redireatWeb(SANOFT_WEB_URLS.PRIVECY_POLICY)}>Privacy Policy</button>
      <button onClick={() => redireatWeb(SANOFT_WEB_URLS.TERMS)}>Terms & Conditions</button>
      <button onClick={() => redireatWeb(SANOFT_WEB_URLS.REFUND_POLICY)}>Refund Policy</button>
    </div>
  );
}

export default Contactus;
