import { createContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DEFAULT_CONFIG, THEME_CONFIG } from '../const';

export const ShopContext = createContext();

export function ShopProvider(props) {
  const history = useHistory();
  const location = useLocation();
  const shopId = location.pathname.split('/')[1];

  const [shop, setShop] = useState(null);
  const [theme, setTheme] = useState(null);

  useEffect(() => {
    const hostname = window.location.hostname;
    let config = THEME_CONFIG[hostname] || DEFAULT_CONFIG;
    if (!shopId && config.shop_id) {
      history.replace(`/${config.shop_id}`);
    }

    if (!config.shop_id) {
      config.shop_id = Number(shopId);
    }

    setShop(config);
    setTheme(config.theme);
  }, [shopId, history]);

  return <ShopContext.Provider value={{ shop, setShop, theme }}>{props.children}</ShopContext.Provider>;
}
