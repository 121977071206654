import React, { useContext, useEffect, useState, useCallback } from 'react';
import TitleBar from '../../utils/TitleBar/TitleBar';
import styles from './Order.module.css';
import { ROUTES, STORAGE_KEY } from '../../../const';
import OrderService from '../../../services/OrderService';
import { ShopContext } from '../../../contexts/ShopContext';
import { AlertMessagesContext } from 'react-alert-messages';
import OrderList from './OrderList';
import { useHistory } from 'react-router-dom';
import { Grid, Hidden } from '@material-ui/core';
import Info from '../../utils/Alerts/Info';
import Loader from '../../utils/Loading';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { OrderContext } from '../../../contexts/OrderContext';
import { ORDER_STATUS } from '../../../const';

function OrderPage() {
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const { shop } = useContext(ShopContext);
  const { orders, setOrders } = useContext(OrderContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const getOrders = useCallback(
    async (sales) => {
      setIsLoading(true);
      try {
        const promises = sales.map((sale) => {
          return OrderService.getOrder(shop?.shop_id, sale?.id, sale?.secret);
        });
        const responseData = await Promise.all(promises);

        const today = new Date();
        const responseDataFiltered = responseData.filter((_order) => {
          if (!_order) {
            return false;
          }
          const orderDate = new Date(_order.created_at);

          const isSameDay =
            orderDate.getUTCFullYear() === today.getUTCFullYear() &&
            orderDate.getUTCMonth() === today.getUTCMonth() &&
            orderDate.getUTCDate() === today.getUTCDate();

          return isSameDay || _order.status === ORDER_STATUS.PENDING;
        });
        localStorage.setItem(STORAGE_KEY.ORDERS, JSON.stringify(responseDataFiltered));
        setOrders(responseDataFiltered);
      } catch (error) {
        postAlertMessage({ text: error.message, type: 'failed' });
      }
      setIsLoading(false);
    },
    [shop?.shop_id, postAlertMessage, setOrders]
  );

  const handleBackToHome = () => {
    history.push(ROUTES.SHOP_HOME.replace(':shopId', shop.shop_id));
  };

  useEffect(() => {
    const sales = localStorage.getItem(STORAGE_KEY.ORDERS);
    if ((sales || []).length) {
      const salesData = JSON.parse(sales);
      getOrders(salesData);
    }
  }, [getOrders]);

  return (
    <>
      <TitleBar />
      <div className={styles.ContentsWrapper}>
        {isLoading && <Loader isOpen={isLoading} />}
        <Grid container className={styles.ContentsArea}>
          <Hidden smUp={true}>
            <Grid container onClick={handleBackToHome} className={styles.backToMenu}>
              <ArrowBackIosIcon style={{ fontSize: '14px' }} />
              <span> Back to menu</span>
            </Grid>
          </Hidden>
          {orders && orders.length ? (
            orders.map((order) => (
              <OrderList
                key={order?.id}
                data={order}
                link={() =>
                  history.push(
                    `${ROUTES.ORDER_DETAILS.replace(':shopId', shop.shop_id).replace(':orderId', order?.id)}`
                  )
                }
              />
            ))
          ) : (
            <Info title={'No Orders Found'} content={'There is no active or recent orders. Please make some orders.'} />
          )}
        </Grid>
      </div>
    </>
  );
}

export default OrderPage;
