import axios from 'axios';
import { SERVER } from '../const';

async function placeOrder(shop, cart, customer) {
  return placeOrderToBackend(shop, cart, customer);
}

async function placeOrderToBackend(shop, cart, customer) {
  const items = Object.keys(cart.products).map((productId) => {
    const cartProduct = cart.products[productId];
    return {
      id: Number(productId),
      quantity: cartProduct?.quantity,
      rate: cartProduct?.product?.price,
      name: cartProduct?.product?.name,
    };
  });
  const order = {
    customer,
    items,
    source: 'online-cart',
    amount: cart?.summary?.total,
    redirect_url: `${window.location.origin}/${shop.shop_id}/payment-status`,
  };
  const resp = await axios.post(`${SERVER.URL}/customer/payments/order/`, order, {
    params: { shop_id: shop.shop_id },
  });
  return resp.data?.data;
}

const getOrderByPayment = async (shopId, paymentId, params) => {
  const resp = await axios.get(`${SERVER.URL}/customer/online-orders/by-payment/${paymentId}/`, {
    params: { shop_id: shopId, ...params },
  });
  return resp.data?.data;
};

const getOrder = async (shopId, orderId, secret) => {
  if (!orderId || !shopId || !secret) {
    return;
  }
  const resp = await axios.get(`${SERVER.URL}/customer/online-orders/${orderId}/`, {
    params: { shop_id: shopId, secret },
  });
  return resp?.data?.data;
};

const OrderService = {
  placeOrder,
  getOrderByPayment,
  getOrder,
};

export default OrderService;
