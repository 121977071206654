import ApiProductService from './ApiProductService';
import CacheHelper from '../helpers/CacheHelper';

async function getCategories(shop) {
  const cacheKey = `${shop.shop_id}-categories`;
  const cache = CacheHelper.get(cacheKey);
  if (cache) {
    return cache;
  }

  const data = await ApiProductService.getCategories(shop);

  CacheHelper.store(cacheKey, data);

  return data;
}

async function getCategory(shop, categoryId) {
  const cacheKey = `category-${shop.shop_id}-${categoryId}`;
  const cache = CacheHelper.get(cacheKey);
  if (cache) {
    return cache;
  }

  const data = ApiProductService.getCategory(shop, categoryId);

  CacheHelper.store(cacheKey, data);

  return data;
}

async function getProducts(shop, category, searchKey) {
  const cacheKey = `${shop.shop_id}-products-${category?.id ?? 'all'}-${searchKey}`;
  const cache = CacheHelper.get(cacheKey);
  if (cache) {
    return cache;
  }

  const data = await ApiProductService.getProducts(shop, category, searchKey);

  CacheHelper.store(cacheKey, data);

  return data;
}

async function getProduct(shop, productId) {
  const cacheKey = `product-${shop.shop_id}-${productId}`;
  const cache = CacheHelper.get(cacheKey);
  if (cache) {
    return cache;
  }

  const data = await ApiProductService.getProduct(shop, productId);

  CacheHelper.store(cacheKey, data);

  return data;
}

const ProductService = {
  getProducts,
  getCategories,
  getCategory,
  getProduct,
};

export default ProductService;
