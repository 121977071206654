import React from 'react';
import styles from './Order.module.css';
import { Paper, Grid, Typography } from '@material-ui/core';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';

function OrderList(props) {
  const { data, link } = props;

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} className={styles.listItemOrderCard}>
      <Paper className={styles.orderWrapper} onClick={link}>
        <div className={styles.orderData}>
          <Grid container>
            <Grid item xs={5} className={styles.titleView}>
              <Typography variant="body2" gutterBottom>
                Order ID
              </Typography>
              <Typography variant="body2" gutterBottom>
                :
              </Typography>
            </Grid>
            <Grid item xs={7} className={styles.value}>
              <Typography variant="body2" gutterBottom>
                {data?.id || '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5} className={styles.titleView}>
              <Typography variant="body2" gutterBottom>
                Order Status
              </Typography>
              <Typography variant="body2" gutterBottom>
                :
              </Typography>
            </Grid>
            <Grid item xs={7} className={styles.value}>
              <Typography variant="body2" gutterBottom>
                {data?.status || '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5} className={styles.titleView}>
              <Typography variant="body2" gutterBottom>
                Date & Time
              </Typography>
              <Typography variant="body2" gutterBottom>
                :
              </Typography>
            </Grid>
            <Grid item xs={7} className={styles.value}>
              <Typography variant="body2" gutterBottom>
                {DateTimeHelpers.getDate(data?.created_at) || '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5} className={styles.titleView}>
              <Typography variant="body2" gutterBottom>
                Amount
              </Typography>
              <Typography variant="body2" gutterBottom>
                :
              </Typography>
            </Grid>
            <Grid item xs={7} className={styles.value}>
              <Typography variant="body2" gutterBottom>
                {data?.online_payment?.amount || '-'}
              </Typography>
            </Grid>
          </Grid>
        </div>
        {/* <div>
          <IconButton>
            <ChevronRightRoundedIcon
              onClick={link}
              className={styles.orderDetailsBtn}
            />
          </IconButton>
        </div> */}
      </Paper>
    </Grid>
  );
}

export default OrderList;
