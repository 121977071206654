import React, { useContext, useState } from 'react';
import styles from './CartSummaryLayout.module.css';
import { ShopContext } from '../../../contexts/ShopContext';
import { DEFAULT_CURRENCY } from '../../../const';
import { Grid } from '@mui/material';
import CartList from '../../popups/CartListDailog';
import PlaceOrderDialog from '../../popups/PlaceOrderDialog';
// import OrderStatusDialog from '../../popups/OrderStatusDialog';
import { CartContext } from '../../../contexts/CartContext';

function CartSummaryLayout() {
  const { shop, theme } = useContext(ShopContext);
  const { cart, itemsCount } = useContext(CartContext);

  const [showCartPopup, setShowCartPopup] = useState(false);
  const [showPlaceOrderPopup, setShowPlaceOrderPopup] = useState(false);
  // const [showOrderStatusPopup, setShowOrderStatusPopup] = useState(false);
  // const [order, setOrder] = useState(null);

  const closeDialog = () => {
    setShowCartPopup(false);
    setShowPlaceOrderPopup(false);
    // setShowOrderStatusPopup(false);
  };

  const openPlaceOrderPopup = () => {
    setShowCartPopup(false);
    setShowPlaceOrderPopup(true);
    // setShowOrderStatusPopup(false);
  };
  const openShowCartPopup = () => {
    if (itemsCount <= 0) return;
    setShowCartPopup(true);
    setShowPlaceOrderPopup(false);
    // setShowOrderStatusPopup(false);
  };
  const openOrderStatusPopup = (order) => {
    // setOrder(order);
    setShowCartPopup(false);
    setShowPlaceOrderPopup(false);
    // setShowOrderStatusPopup(true);
  };

  return (
    <>
      <Grid container className={styles.ContentArea}>
        <Grid item xs={6} className={styles.subtotalArea}>
          <div>
            <header>Subtotal:</header>
            <div>
              <b>
                {shop?.config?.currency || DEFAULT_CURRENCY}{' '}
                {cart?.summary?.payable || 0}
              </b>
            </div>
          </div>
          <div className={styles.count}>
            <header>Count</header>
            <div>
              <b>{itemsCount || 0}</b>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          onClick={openShowCartPopup}
          className={styles.layoutPlaceOrder}
          style={{ backgroundColor: theme?.primary }}
        >
          <div className={styles.placeOrderBtn}>PLACE ORDER</div>
        </Grid>
      </Grid>
      {showCartPopup && (
        <CartList
          shopId={shop?.shop_id}
          showPopup={true}
          cart={cart}
          dismissPopup={closeDialog}
          openPlaceOrderPopup={openPlaceOrderPopup}
        />
      )}
      {showPlaceOrderPopup && (
        <PlaceOrderDialog
          shop={shop}
          cart={cart}
          showPopup={true}
          fnOnClose={closeDialog}
          fnOnBack={openShowCartPopup}
          openOrderStatusPopup={openOrderStatusPopup}
        />
      )}
      {/* {showOrderStatusPopup && (
        <OrderStatusDialog
          showPopup={true}
          order={order}
          fnOnClose={closeDialog}
        />
      )} */}
    </>
  );
}

export default CartSummaryLayout;
