import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import PageNotFound from './components/pages/PageNotFoundPage/PageNotFound';
import { ShopProvider } from './contexts/ShopContext';
import ShopHomePage from './components/pages/ShopHomePage';
import ProductDetailsPage from './components/pages/ProductDetailsPage';
import ShopRoute from './components/utils/ShopRoute';
import { CartProvider } from './contexts/CartContext';
import AlertMessagesProvider from 'react-alert-messages';
import OrderPage from './components/pages/OrderPage/OrderPage';
import OrderDetails from './components/pages/OrderDetails/OrderDetails';
import { ROUTES } from './const';
import { OrderProvider } from './contexts/OrderContext';
import PaymentStatusPage from './components/pages/PaymentStatus/PaymentStatus';
import ContactusPage from './components/pages/Info/Contactus';

function App() {
  return (
    <BrowserRouter>
      <AlertMessagesProvider>
        <ShopProvider>
          <CartProvider>
            <OrderProvider>
              <Switch>
                <ShopRoute exact={true} path={ROUTES.SHOP_HOME} component={ShopHomePage} />
                <ShopRoute exact={true} path={ROUTES.ORDERS} component={OrderPage} />
                <ShopRoute exact={true} path={ROUTES.ORDER_DETAILS} component={OrderDetails} />
                <ShopRoute exact={true} path={ROUTES.PRODUCT_DETAILS} component={ProductDetailsPage} />
                <ShopRoute exact={true} path={ROUTES.PAYMENT_STATUS} component={PaymentStatusPage} />
                <ShopRoute exact={true} path={ROUTES.CONTACT_US} component={ContactusPage} />
                <Route path="/*">
                  <PageNotFound />
                </Route>
              </Switch>
            </OrderProvider>
          </CartProvider>
        </ShopProvider>
      </AlertMessagesProvider>
    </BrowserRouter>
  );
}

export default App;
