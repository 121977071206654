import React, { useCallback, useContext, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Grid, TextField } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import styles from './styles.module.css';
import CartService from '../../services/CartService';
import OrderService from '../../services/OrderService';
import LoadingDialog from '../utils/LoadingDialog';
import { CartContext } from '../../contexts/CartContext';
import { AlertMessagesContext } from 'react-alert-messages';
import { DEFAULT_CURRENCY, STORAGE_KEY } from '../../const';

export default function PlaceOrderDialog(props) {
  const { clearCartItems } = useContext(CartContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const { shop, cart, fnOnBack, fnOnClose } = props;
  
  const [customer, setCustomer] = useState({});
  const [showLoading, setShowLoading] = useState(false);

  const _placeOrder = useCallback(async () => {
    setShowLoading(true);
    try {
      const cart = CartService.getCart(shop.shop_id);
      const resp = await OrderService.placeOrder(shop, cart, customer);
      clearCartItems(shop.shop_id);
      fnOnClose();
      if (resp) {
        window.location.href = resp?.payment_url;
      }
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setShowLoading(false);
  }, [shop, fnOnClose, clearCartItems, postAlertMessage, customer]);

  const placeOrderBtnPressed = async () => {
    if (!customer?.name?.trim() || !customer?.mobile?.trim()) {
      postAlertMessage({ text: 'Both phone number and name are required.', type: 'failed' });
      return;
    }

    if (customer?.mobile.length < 10) {
      postAlertMessage({ text: 'Please enter a valid 10-digit phone number.', type: 'failed' });
      return;
    }
    localStorage.setItem(STORAGE_KEY.CUSTOMER_DETAILS, JSON.stringify(customer));
    await _placeOrder(customer);
  };

  useEffect(() => {
    const customer = localStorage.getItem(STORAGE_KEY.CUSTOMER_DETAILS);
    setCustomer(customer ? JSON.parse(customer) : {});
  }, []);

  if (showLoading) {
    return <LoadingDialog show={props.showPopup} onClose={props.dismissPopup} />;
  }

  return (
    <>
      <Dialog open={props.showPopup} onClose={props.dismissPopup} maxWidth="sm" fullWidth>
        <DialogTitle id="alert-dialog-title">Place Order</DialogTitle>
        <DialogContent className={styles.popupContentDiv}>
          <Grid container style={{ padding: 16 }} rowSpacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Phone"
                name="Phone"
                type="number"
                size="small"
                color="secondary"
                value={customer?.mobile}
                fullWidth
                onChange={(e) => setCustomer({ ...customer, mobile: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Name"
                name="Name"
                type="text"
                size="small"
                color="secondary"
                value={customer?.name}
                fullWidth
                onChange={(e) => setCustomer({ ...customer, name: e.target.value })}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <div className={styles.CartTotalDiv}>
            <div>Subtotal:</div>
            <div>
              <b>
                {shop?.config?.currency || DEFAULT_CURRENCY} {cart.summary?.total}
              </b>
            </div>
          </div>

          <div>
            <Button style={{ color: red[600] }} onClick={fnOnBack}>
              BACK
            </Button>
            <Button style={{ color: green[600] }} onClick={placeOrderBtnPressed}>
              PLACE ORDER
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
