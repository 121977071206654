import React, { useContext, useEffect, useState } from 'react';
import styles from './styles.module.css';
import TitleBar from '../../utils/TitleBar/TitleBar';
import { Hidden } from '@material-ui/core';
import { QR_PREFIX, ROUTES, STORAGE_KEY } from '../../../const';
import { useHistory, useParams } from 'react-router-dom';
import { ShopContext } from '../../../contexts/ShopContext';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import QRCode from 'qrcode.react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import { Grid } from '@mui/material';

function OrderDetails() {
  const [orderData, setOrderData] = useState(null);
  const { theme } = useContext(ShopContext);

  const history = useHistory();
  const { shopId, orderId } = useParams();

  const handleBackToOrders = () => {
    history.push(ROUTES.ORDERS.replace(':shopId', shopId));
  };

  const handleBackToHome = () => {
    history.push(ROUTES.SHOP_HOME.replace(':shopId', shopId));
  };

  useEffect(() => {
    const sales = JSON.parse(localStorage.getItem(STORAGE_KEY.ORDERS));
    const sale = sales.find((s) => s?.id === Number(orderId)) || {};
    setOrderData(sale);
  }, [orderId]);

  return (
    <>
      <TitleBar />
      <Grid className={styles.ContentWrapper}>
        <Hidden smUp={true}>
          <Grid container justifyContent="space-between" style={{ margin: '4px 0px' }}>
            <Grid item onClick={handleBackToOrders} className={styles.backToMenu}>
              <ArrowBackIosIcon style={{ fontSize: '14px' }} />
              <span> Back to orders</span>
            </Grid>
            <Grid item onClick={handleBackToHome} className={styles.backToMenu}>
              <HomeRoundedIcon />
            </Grid>
          </Grid>
        </Hidden>
        <div className={styles.container}>
          <Grid className={styles.statusContainter} container>
            <QRCode
              value={QR_PREFIX + JSON.stringify({ id: orderData?.id, secret: orderData?.secret })}
              size={180}
            />
            <Grid item container rowSpacing={1} className={styles.orderContainer}>
              <Grid item xs={12}>
                <span className={styles.subHead}>Order details</span>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="space-between">
                <span className={styles.subText}>Order No :</span>
                <span className={styles.text}>{orderData?.id}</span>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="space-between">
                <span className={styles.subText}>Order Date :</span>
                <span className={styles.text}>{DateTimeHelpers.getDate(orderData?.created_at)}</span>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="space-between">
                <span className={styles.subText}>Customer name :</span>
                <span className={styles.text}>{orderData?.details?.customer?.name}</span>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="space-between">
                <span className={styles.subText}>Phone No :</span>
                <span className={styles.text}>{orderData?.details?.customer?.mobile}</span>
              </Grid>
              <Grid item container rowSpacing={1} xs={12} pt={1} mt={0.5}>
                <Grid item xs={4} display="flex" justifyContent="center" className={styles.title}>
                  <span>Items</span>
                </Grid>
                <Grid item xs={4} display="flex" justifyContent="center" className={styles.title}>
                  <span>Qty</span>
                </Grid>
                <Grid item xs={4} display="flex" justifyContent="center" className={styles.title}>
                  <span>Total</span>
                </Grid>
                {orderData?.details?.items.map((item) => (
                  <>
                    <Grid item xs={4} display="flex" justifyContent="center">
                      <span className={styles.text}>{item.name}</span>
                    </Grid>
                    <Grid item xs={4} display="flex" justifyContent="center">
                      <span className={styles.text}>x{item.quantity}</span>
                    </Grid>

                    <Grid item xs={4} display="flex" justifyContent="center">
                      <span className={styles.text}>{item.rate * item.quantity}</span>
                    </Grid>
                  </>
                ))}
                <Grid item xs={4} mt={1} display="flex" justifyContent="center" className={styles.title}>
                  <span>Subtotal</span>
                </Grid>
                <Grid item xs={4} mt={1} display="flex" justifyContent="center" className={styles.title}>
                  <span></span>
                </Grid>
                <Grid item xs={4} mt={1} display="flex" justifyContent="center" className={styles.title}>
                  <span>{orderData?.details?.amount}</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <button
                className={styles.btn}
                style={{ backgroundColor: theme?.primary ?? '#00923e' }}
                onClick={window.print}
              >
                Download QR
              </button>
            </Grid>
            <Hidden smDown={true}>
              <Grid item xs={12} display="flex" justifyContent="center" columnGap={2}>
                <button className={styles.btn} style={{ backgroundColor: theme?.secondary }} onClick={handleBackToHome}>
                  Home
                </button>
                <button
                  className={styles.btn}
                  style={{ backgroundColor: theme?.secondary }}
                  onClick={handleBackToOrders}
                >
                  Orders
                </button>
              </Grid>
            </Hidden>
          </Grid>
        </div>
      </Grid>
    </>
  );
}

export default OrderDetails;
