import { createContext, useState, useEffect, useContext } from 'react';
import { STORAGE_KEY } from '../const';
import OrderService from '../services/OrderService';
import { ShopContext } from './ShopContext';

export const OrderContext = createContext();

export function OrderProvider(props) {
  const { shop } = useContext(ShopContext);

  const [orders, setOrders] = useState([]);
  const [ordersCount, setOrdersCount] = useState(0);

  const countOrders = () => {
    const orders = JSON.parse(localStorage.getItem(STORAGE_KEY.ORDERS) || '[]');
    setOrdersCount(orders.length);
  };

  const getOrder = async (sale) => {
    const res = await OrderService.getOrder(sale, shop?.shop_id);
    setOrders([...orders, res.data]);
  };

  useEffect(() => {
    countOrders();
  }, []);

  return (
    <OrderContext.Provider
      value={{
        orders,
        setOrders,
        countOrders,
        getOrder,
        ordersCount,
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
}
